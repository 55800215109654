<template>
  <div>
    <CustomDialog
      :open.sync="open"
      :title="isEdit ? `Edit SMS/MMS Campaign` : `Create SMS/MMS Campaign`"
      @click-close="close_dialog"
      :max-width="mdAndDown ? `100%` : 1024"
      ref="dialog"
      fullscreen
    >
      <template v-slot:content>
        <v-card flat>
          <v-card-text class="py-0">
            <v-form v-model="valid">
              <v-row dense>
                <v-col md="9">
                  <v-stepper
                    v-model="progress"
                    light
                    class="border-color--grey border-1"
                  >
                    <v-stepper-header>
                      <v-stepper-step
                        :rules="[step1Rules]"
                        :complete="progress > 1"
                        :step="1"
                      >
                        Settings
                      </v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step
                        :rules="[step2Rules]"
                        :complete="progress > 2"
                        :step="2"
                      >
                        Scripts & Schedules
                      </v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :rules="[step3Rules]" :step="3">
                        Previews
                      </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content :step="1" class="pa-1">
                        <v-card flat :min-height="400">
                          <v-card-text>
                            <v-text-field
                              filled
                              dense
                              clearable
                              clear-icon="mdi-close-circle-outline"
                              v-model="mapping.name"
                              label="Campaign Name"
                              hide-details="auto"
                              placeholder="Required"
                              :rules="requiredRules"
                              class="mb-2"
                            ></v-text-field>
                            <v-autocomplete
                              v-if="recipient_type === 'leads'"
                              v-model="mapping.leads"
                              :items="search_items"
                              :loading="isLoading"
                              :search-input.sync="search_word"
                              chips
                              deletable-chips
                              multiple
                              clearable
                              clear-icon="mdi-close-circle-outline"
                              hide-details="auto"
                              item-text="fullname"
                              item-value="id"
                              label="Search for leads"
                              placeholder="Required"
                              :rules="requiredRules"
                              filled
                              dense
                              class="mb-3"
                              cache-items
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    Search for
                                    <strong>leads</strong>
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                              <template
                                v-slot:selection="{
                                  attr,
                                  on,
                                  item: ohMyItem,
                                  index,
                                  selected
                                }"
                              >
                                <v-chip
                                  v-bind="attr"
                                  :input-value="selected"
                                  label
                                  small
                                  outlined
                                  clearable
                                  v-on="on"
                                  v-if="index < 2"
                                >
                                  <span v-text="ohMyItem.fullname"></span>
                                </v-chip>
                                <v-chip
                                  v-bind="attr"
                                  :input-value="selected"
                                  label
                                  small
                                  outlined
                                  clearable
                                  v-on="on"
                                  v-if="index === 2"
                                >
                                  <span>
                                    + {{ mapping.leads.length - 2 }} others
                                  </span>
                                </v-chip>
                              </template>
                              <template v-slot:item="{ item: myItem }">
                                <v-list-item-content :key="myItem.id">
                                  <v-list-item-title
                                    v-text="myItem.fullname"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                            <v-select
                              dense
                              v-else
                              filled
                              :rules="requiredRules"
                              clear-icon="mdi-close-circle-outline"
                              label="Recipients"
                              placeholder="Required"
                              hide-details="auto"
                              multiple
                              small-chips
                              :items="groups"
                              item-text="name"
                              item-value="id"
                              deletable-chips
                              chips
                              clearable
                              v-model="mapping.groups"
                              class="mb-2"
                            ></v-select>

                            <v-text-field
                              filled
                              dense
                              clearable
                              clear-icon="mdi-close-circle-outline"
                              v-model="mapping.email_response_to"
                              label="Email Response Notification"
                              :rules="emailRules"
                              hide-details="auto"
                              placeholder="Optional"
                            ></v-text-field>
                            <v-radio-group v-model="messaging" column>
                              <span class="caption mb-1 text--secondary">
                                Campaign Type
                              </span>
                              <v-radio
                                label="Single messages for all iteration campaign"
                                value="same_all_iteration"
                              ></v-radio>
                              <v-radio
                                label="Unique messages per iteration campaign"
                                value="unique_per_iteration"
                              ></v-radio>
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                      <v-stepper-content :step="2" class="pa-1">
                        <v-card flat :min-height="400">
                          <v-card-text>
                            <v-row dense>
                              <v-col md="6">
                                <v-text-field
                                  v-model="number_of_iterations"
                                  type="number"
                                  label="Number of iteration (max 20)"
                                  dense
                                  @keydown="false"
                                  :rules="minMaxRules"
                                  hide-details="auto"
                                  min="1"
                                  max="20"
                                  placeholder="Required. Value from 1 to 20"
                                  filled
                                  class="mb-2"
                                ></v-text-field>
                              </v-col>
                              <v-btn-toggle
                                dense
                                v-model="mapping.message_type"
                                class="mx-auto mt-4"
                              >
                                <v-btn value="sms" text>SMS</v-btn>
                                <v-btn value="mms" text>MMS</v-btn>
                              </v-btn-toggle>
                            </v-row>
                            <v-row dense>
                              <v-col
                                md="12"
                                v-show="messaging === 'same_all_iteration'"
                              >
                                <v-textarea
                                  dense
                                  filled
                                  :rules="requiredRules"
                                  auto-grow
                                  label="Contents for all iterations"
                                  placeholder="Required"
                                  hide-details="auto"
                                  :value="global_message"
                                  clearable
                                  v-model="global_message"
                                  rows="3"
                                  clear-icon="mdi-close-circle-outline"
                                  class="mb-2"
                                >
                                </v-textarea>
                              </v-col>
                              <v-col md="6" class="mb-2">
                                <CustomTimePicker
                                  filled
                                  dense
                                  label="Operating Hours (From)"
                                  :max="mapping.op_hours_to"
                                  placeholder="Optional"
                                  v-model="mapping.op_hours_from"
                                ></CustomTimePicker>
                              </v-col>
                              <v-col md="6" class="mb-2">
                                <CustomTimePicker
                                  filled
                                  dense
                                  :min="mapping.op_hours_from"
                                  :disabled="!mapping.op_hours_from"
                                  label="Operating Hours (Until)"
                                  placeholder="Required if Operating Hours(From) is set"
                                  v-model="mapping.op_hours_to"
                                ></CustomTimePicker>
                              </v-col>
                            </v-row>
                            <v-expansion-panels
                              v-model="panels"
                              multiple
                              flat
                              :key="counter"
                            >
                              <v-expansion-panel
                                v-for="(iteration, index) in iteration_items"
                                :key="index"
                                class="border-1 border-color--grey"
                              >
                                <v-expansion-panel-header class="subtitle-2">
                                  Iteration : {{ iteration.step }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-row dense>
                                    <v-col
                                      md="12"
                                      v-if="
                                        messaging === 'unique_per_iteration'
                                      "
                                    >
                                      <span class="subtitle-2 fullwidth">
                                        Contents for Iteration {{ index + 1 }}
                                      </span>
                                      <v-textarea
                                        v-if="
                                          messaging !== 'same_all_iteration'
                                        "
                                        dense
                                        filled
                                        :rules="requiredRules"
                                        rows="3"
                                        auto-grow
                                        clear-icon="mdi-close-circle-outline"
                                        placeholder="Required"
                                        hide-details="auto"
                                        :value="global_message"
                                        clearable
                                        v-model="iteration.message"
                                        class="mb-2"
                                      ></v-textarea>
                                    </v-col>
                                    <v-col
                                      md="12"
                                      v-if="mapping.message_type === 'mms'"
                                    >
                                      <span class="subtitle-2">
                                        Attachment (Max file size: 5MB)
                                      </span>
                                      <v-file-input
                                        @change="
                                          handleFileChange($event, index)
                                        "
                                        dense
                                        filled
                                        label="MMS Attachment"
                                        small-chips
                                        placeholder="Optional"
                                        chips
                                        clearable
                                        clear-icon="mdi-close-circle-outline"
                                        hide-details="auto"
                                        @click:clear="
                                          iteration.attachment = null
                                        "
                                      ></v-file-input>
                                    </v-col>
                                    <v-col md="12">
                                      <span class="subtitle-2">Schedule</span>
                                      <span class="caption ml-2">
                                        (This iteration will be sent one time
                                        during this period)
                                      </span>

                                      <DateRangePicker
                                        ref="picker"
                                        class="mt-2 mx-auto"
                                        :min-date="today"
                                        opens="inline"
                                        :key="index"
                                        :date-range="iteration.schedule"
                                        :show-week-numbers="false"
                                        :ranges="false"
                                        :time-picker="false"
                                        :show-dropdowns="false"
                                        auto-apply
                                        @update="handleUpdate($event, index)"
                                      >
                                        <template
                                          v-slot:input="picker"
                                          style="min-width: 350px"
                                        >
                                          <template v-if="picker.startDate">
                                            Around
                                            <v-chip
                                              label
                                              class="mr-1 border-0"
                                              outlined
                                            >
                                              {{
                                                moment(picker.startDate).format(
                                                  'YYYY-MM-DD'
                                                )
                                              }}
                                            </v-chip>
                                            and
                                            <v-chip
                                              class="ml-1 border-0"
                                              outlined
                                              label
                                            >
                                              {{
                                                moment(picker.endDate).format(
                                                  'YYYY-MM-DD'
                                                )
                                              }}
                                            </v-chip>
                                          </template>
                                          <div v-else>
                                            Select start date and end date from
                                            below
                                          </div>
                                        </template>
                                      </DateRangePicker>
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                      <v-stepper-content :step="3" class="pa-1">
                        <v-card flat :min-height="400">
                          <v-card-text>
                            <v-simple-table
                              class="fullwidth border-1 border-color--grey"
                            >
                              <template v-slot:default>
                                <tbody v-if="payload">
                                  <tr>
                                    <td class="pl-2">Campaign Name :</td>
                                    <td>
                                      {{
                                        payload.name ||
                                        'Not set. This is a required field!'
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">Send to :</td>
                                    <td>{{ groups_in_text }}</td>
                                  </tr>
                                  <!--                                <tr>
                                  <td class="pl-2">Reply To :</td>
                                  <td>{{ payload.reply_to || 'None' }}</td>
                                </tr>-->
                                  <tr>
                                    <td class="pl-2">
                                      Use email for response notification :
                                    </td>
                                    <td>
                                      {{ payload.email_response_to || 'None' }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">Message Type :</td>
                                    <td>
                                      {{
                                        payload.message_type.toUpperCase() ||
                                        'SMS'
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pl-2">Number of Iteration :</td>
                                    <td>
                                      {{
                                        iteration_items.length ||
                                        'Empty. This is a required field!'
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <v-card
                              class="mt-1"
                              flat
                              v-if="payload.iterations.length"
                            >
                              <v-window v-model="preview_step">
                                <v-window-item
                                  class="border-1 border-color--grey rounded-10"
                                  :value="index"
                                  v-for="(item2, index) in payload.iterations"
                                  :key="index"
                                >
                                  <v-card-text>
                                    <h5 class="subtitle-1">
                                      Iteration : {{ index + 1 }}
                                    </h5>
                                    <p>
                                      Message :
                                      {{ item2.message || 'No message!' }}
                                    </p>
                                    <p>
                                      Attachment :
                                      {{
                                        item2.attachment
                                          ? item2.attachment.filename
                                          : 'No attachment!'
                                      }}
                                    </p>
                                    <p>
                                      Schedule:
                                      <v-chip outlined class="mr-1" label>
                                        {{
                                          item2.schedule
                                            ? item2.schedule.startDate ||
                                              'Not Set'
                                            : 'Not set'
                                        }}
                                      </v-chip>
                                      -
                                      <v-chip outlined class="ml-1" label>
                                        {{
                                          item2.schedule
                                            ? item2.schedule.endDate ||
                                              'Not Set'
                                            : 'Not set'
                                        }}
                                      </v-chip>
                                    </p>
                                  </v-card-text>
                                </v-window-item>
                              </v-window>
                              <v-card-actions
                                v-if="payload.iterations.length > 1"
                              >
                                <v-btn
                                  text
                                  class="text-none"
                                  :disabled="preview_step === 0"
                                  @click="preview_step--"
                                  small
                                >
                                  Previous Iteration
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  class="text-none"
                                  :disabled="
                                    preview_step ===
                                    payload.iterations.length - 1
                                  "
                                  @click="preview_step++"
                                  small
                                >
                                  Next Iteration
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-card-text>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
                <v-col md="3">
                  <v-sheet
                    class="d-flex"
                    color="primary lighten-3"
                    height="100%"
                  >
                  </v-sheet>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:entire-actions>
        <v-row dense>
          <v-btn
            v-if="progress > 1"
            text
            class="text-none"
            outlined
            @click="progress--"
          >
            Back
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="progress < 3"
            text
            class="text-none"
            :disabled="!can_next"
            @click="progress += 1"
            outlined
          >
            Next
          </v-btn>
          <v-btn
            v-if="progress > 2"
            :loading="btnloading"
            text
            class="text-none"
            :disabled="!can_submit"
            @click="save"
            outlined
          >
            {{ isEdit ? 'Update' : 'Save' }}
          </v-btn>
        </v-row>
      </template>
    </CustomDialog>
    <DeleteDialog
      :open-dialog.sync="confirm_edit_campaign"
      title="Updating Campaign"
      text-content="Are you sure you want to update this campaign?<br> This will remove any previous iterations and pending SMS/MMS generated for this campaign."
      @delete="proceedUpdate"
      delete-button-text="Yes! Update Campaign"
    ></DeleteDialog>
  </div>
</template>
<script>
import request from '@/services/axios_instance'
import DeleteDialog from '@/common/DeleteDialog.vue'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import DateRangePicker from 'vue2-daterange-picker'
import TimePicker from '@/common/TimePicker.vue'
import CustomTimePicker from '@/common/Pickers/CustomTimePicker.vue'
import moment from 'moment'
import _debounce from 'lodash/debounce'
export default {
  name: 'SMSCampaignCreator',
  components: {
    CustomTimePicker,
    TimePicker,
    CustomDialog,
    DeleteDialog,
    DateRangePicker
  },
  props: {
    dialog: { type: Boolean, default: false },
    targetType: { type: String, default: 'groups' },
    item: Object,
    targets: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    open: false,
    groups: [],
    number_of_iterations: 1,
    messaging: 'same_all_iteration',
    confirm_edit_campaign: false,
    mapping: {
      name: null,
      message_type: 'sms',
      groups: [],
      leads: [],
      reply_to: null,
      email_response_to: null,
      op_hours_from: '00:01',
      op_hours_to: '23:59'
    },
    iteration_items: [],
    progress: 1,
    preview_step: 0,
    counter: 0,
    panels: [],
    btnloading: false,
    global_message: null,
    valid: false,
    requiredRules: [(v) => !!v || 'This field is required'],
    fileRules: [(v) => (v && v.length < 2) || 'Max upload of 1 file'],
    emailRules: [(v) => !v || /.+@.+/.test(v) || 'Invalid Email address'],
    minMaxRules: [
      (v) => !!v || 'This field is required',
      (v) => (v && parseInt(v) > 0) || 'Min value is 1',
      (v) => (v && parseInt(v) < 21) || 'Max value is 20'
    ],
    placeholders: [
      'target_first_name',
      'target_last_name',
      'target_fullname',
      'target_organization',
      'target_email',
      'from_website',
      'from_organization'
    ],
    search_items: [],
    isLoading: false,
    search_word: null,
    recipient_type: 'groups'
  }),
  watch: {
    targetType: {
      handler: function (val) {
        this.recipient_type = val
      },
      immediate: true
    },
    dialog: {
      handler: function (val) {
        this.open = val
        this.btnloading = false
        if (val && this.recipient_type === 'groups') {
          this.getGroups()
        }
      },
      immediate: true
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    number_of_iterations: {
      handler: function (val) {
        if (val > 0 && val <= 20 && this.isEdit === false) {
          this.iteration_items = []
          for (let i = 0; i < val; i++) {
            this.iteration_items.push({
              step: i + 1,
              attachment: null,
              message:
                this.messaging === 'same_all_iteration'
                  ? this.global_message || null
                  : null,
              schedule: {
                startDate: null,
                endDate: null
              }
            })
          }
          this.$nextTick(this.open_all)
          this.counter++
        }
      },
      immediate: true
    },
    item: {
      handler: function (val) {
        if (val) {
          this.mapItem(val)
        }
      },
      immediate: true,
      deep: true
    },
    targets: {
      handler: function (val) {
        if (val.length > 0) {
          this.mapping.leads = val.map((i) => {
            return i.hasOwnProperty('id') ? i.id : i
          })
          this.search_items = val
        } else {
          this.mapping.leads = []
        }
      },
      deep: true,
      immediate: true
    },
    search_word: _debounce(function (val) {
      this.search(val)
    }, 500)
  },
  methods: {
    getGroups() {
      request.get(`api/leads/groups?all=true`).then(({ data }) => {
        this.groups = data
      })
    },
    close_dialog() {
      this.open = false
    },
    save() {
      if (!this.isEdit) {
        this.btnloading = true
        this.$emit('save', this.payload)
      } else {
        this.confirm_edit_campaign = true
      }
    },
    proceedUpdate() {
      this.btnloading = true
      this.confirm_edit_campaign = false
      this.$emit('update', this.payload)
    },
    search(keyword) {
      if (
        !this.isLoading &&
        keyword &&
        (keyword !== 'undefined' || typeof keyword === 'undefined')
      ) {
        this.isLoading = true
        request
          .get(`api/leads?search=${keyword}&per_page=10`)
          .then(({ data }) => {
            if (data.data) this.search_items = data.data
          })
          .finally(() => (this.isLoading = false))
      }
    },
    step1Rules() {
      let is_recipient_ok =
        this.targetType === 'groups'
          ? this.mapping.groups && this.mapping.groups.length
          : this.mapping.leads && this.mapping.leads.length
      return !!(
        this.mapping.name &&
        this.mapping.name.length &&
        is_recipient_ok
      )
    },
    step2Rules() {
      if (!this.payload.iterations.length) return false
      for (let i = 0; i < this.payload.iterations.length; i++) {
        let iteration = this.payload.iterations[i]
        if (
          !iteration.message ||
          !iteration.schedule.startDate ||
          !iteration.schedule.endDate
        )
          return false
      }
      return true
    },
    step3Rules() {
      return this.step1Rules() && this.step2Rules()
    },
    open_all() {
      this.panels = [...Array(this.iteration_items).keys()].map((k, i) => i)
    },
    handleUpdate(event, index) {
      this.iteration_items[index].schedule = {
        startDate: this.moment(event.startDate).format('YYYY-MM-DD'),
        endDate: this.moment(event.endDate).format('YYYY-MM-DD')
      }
    },
    handleFileChange(file, index) {
      if (!file || typeof file === 'undefined') return
      if (file.size <= 1000000 * 5) {
        //filesize limit for twilio is 5mb https://www.twilio.com/docs/sms/send-messages#example-1
        let formData = new FormData()
        formData.append('file', file)
        request.post(`api2/attachments/dropzone`, formData).then(
          ({ data }) => {
            this.iteration_items[index].attachment = data
          },
          (error) => {
            this.appSnackbar(error, 'error')
          }
        )
      } else {
        this.appSnackbar(`${file.name} exceed 5MB filesize limit`, 'error')
      }
    },
    mapItem(item) {
      this.recipient_type = item.target_type
      this.messaging = item.props.templating
      this.mapping.op_hours_from = item.props.op_hours_from || null
      this.mapping.op_hours_to = item.props.op_hours_to || null
      this.mapping.id = item.id
      this.message_type = item.message_type || 'sms'
      this.mapping.name = item.name
      this.mapping.reply_to = item.props.reply_to || null
      this.mapping.email_response_to = item.props.email_response_to || null
      this.mapping.groups = item.groups.map((i) => {
        return i.hasOwnProperty('id') ? i.id : i
      })
      this.mapping.leads = item.leads.map((i) => {
        return i.hasOwnProperty('id') ? i.id : i
      })
      this.search_items = item.leads
      this.global_message =
        item.props.templating === 'same_all_iteration'
          ? item.iterations[0].props.message
          : null
      this.iteration_items = []
      for (let i = 0; i < this.number_of_iterations; i++) {
        this.iteration_items.push({
          step: i + 1,
          message: item.iterations[i].props.message || null,
          attachment:
            this.mapping.message_type === 'mms'
              ? item.iterations[i].props.attachment
              : null,
          schedule: {
            startDate: moment(item.iterations[i].startDate).format(
              'YYYY-MM-DD'
            ),
            endDate: moment(item.iterations[i].endDate).format('YYYY-MM-DD')
          }
        })
      }
      this.number_of_iterations = item.iterations.length || 0
      this.$nextTick(this.open_all)
    }
  },
  computed: {
    isEdit() {
      return !!this.item
    },
    today() {
      return this.moment(new Date()).format('YYYY-MM-DD')
    },
    can_proceed_2() {
      return this.step1Rules()
    },
    can_proceed_3() {
      return this.step2Rules()
    },
    can_submit() {
      return this.step3Rules()
    },
    can_next() {
      if (this.progress === 1) return this.can_proceed_2
      else if (this.progress === 2) return this.can_proceed_3
      else return false
    },
    payload() {
      return {
        type: this.messaging,
        name: this.mapping.name,
        message_type: this.mapping.message_type,
        reply_to: this.mapping.reply_to,
        email_response_to: this.mapping.email_response_to,
        groups: this.mapping.groups,
        leads: this.mapping.leads,
        iterations: this.iteration_items.map((i) => {
          return {
            attachment:
              this.mapping.message_type === 'mms' ? i.attachment : null,
            message:
              this.messaging === 'same_all_iteration'
                ? this.global_message || null
                : i.message,
            schedule: {
              startDate: i.schedule.startDate,
              endDate: i.schedule.endDate
            }
          }
        }),
        op_hours_from: this.mapping.op_hours_from || null,
        op_hours_to: this.mapping.op_hours_to || null
      }
    },
    groups_in_text() {
      if (this.targetType === 'groups') {
        let selected = this.groups.filter((i) =>
          this.mapping.groups.includes(i.id)
        )
        return selected.length
          ? selected
              .map((j) => {
                return j.name
              })
              .join(',')
          : 'No groups selected'
      } else {
        return 'Selected leads'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-stepper {
  box-shadow: none !important;

  .v-stepper__header {
    box-shadow: none !important;
  }
}
</style>
