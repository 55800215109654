<template>
  <div class="email-campaign">
    <VueTable
      :items="items"
      :outlined="false"
      flat
      min-height="80vh"
      :headers="headers"
      :show-row-actions="true"
      @load-more="loadMoreCampaign"
      :show-select="false"
      icon="mdi-alpha-c-box-outline"
      :has-header="false"
      :key="componentKey"
      :no-more-data="!has_more_items"
      empty-text="No campaigns yet"
      :loading="loading_items"
    >
      <template v-slot:header-left-toolbar>
        <h1 class="my-2 primary--text fw-700 ls-1 fs-16">
          Email Campaign Analytics
        </h1>
      </template>
      <template v-slot:header-toolbar>
        <v-row dense align="center" justify="end">
          <v-btn
            @click="$router.push({ name: 'email-settings' })"
            color="primary"
            class="text-none ml-1"
          >
            <v-icon left>mdi-cog</v-icon> Settings
          </v-btn>
          <v-menu left bottom offset-y>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    depressed
                    color="primary"
                    class="ml-1 text-none light--text"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon left>mdi-plus-circle-multiple-outline</v-icon>
                    Create ...
                  </v-btn>
                </template>
                <span>Create ...</span>
              </v-tooltip>
            </template>
            <v-list dense>
              <v-list-item @click="add_edit_campaign(null)">
                <v-list-item-icon class="mr-2">
                  <v-icon color="green">mdi-plus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="fw-700 primary--text">
                  Create New Email Campaign
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({ name: 'builders-email--template-campaign' })
                "
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="purple">mdi-signature-text</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="fw-700 primary--text">
                  Create New Email Template
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="createSignature">
                <v-list-item-icon class="mr-2">
                  <v-icon color="pink">mdi-signature-image</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="fw-700 primary--text">
                  Create New Email Signature
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                class="ml-1 text-none"
                v-bind="attrs"
                v-on="on"
              >
                Status: {{ filters.status | ucwords }}
                <v-spacer></v-spacer>
                <v-icon right>mdi-menu-swap</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="item in ['all', 'active', 'inactive']"
                :key="item"
                @click="filters.status = item"
              >
                <v-list-item-title class="fw-700 primary--text">
                  {{ item | ucwords }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                class="ml-1 text-none"
                v-bind="attrs"
                v-on="on"
              >
                <span>Templates</span>
                <v-icon right>mdi-menu-swap</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                @click="
                  $router.push({
                    name: 'email-accessories',
                    params: { type: 'templates' }
                  })
                "
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="purple">mdi-signature-text</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="fw-700 primary--text">
                  Email Templates
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({
                    name: 'email-accessories',
                    params: { type: 'signatures' }
                  })
                "
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="pink">mdi-signature-image</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="fw-700 primary--text">
                  Email Signatures
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
      <template v-slot:header>
        <v-sheet class="mb-2">
          <v-row dense align="stretch" justify="center">
            <v-col
              :cols="mdAndUp ? 7 : 12"
              class="border-1 ma-1 border-color--grey rounded-10"
            >
              <apexchart
                height="160"
                type="line"
                :options="options"
                :series="series"
              ></apexchart>
            </v-col>
            <v-sheet class="col ma-1 rounded-10" outlined>
              <v-tabs fixed-tabs>
                <v-tab class="text-none fw-700 fs-16">Campaigns</v-tab>
                <v-tab class="text-none fw-700 fs-16">Emails</v-tab>
                <v-tab-item class="fullwidth fill-height">
                  <v-sheet class="pa-3 fullwidth my-2">
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Total: </span>
                      <span> {{ analytics.campaigns.total || 0 }} </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Active: </span>
                      <span>
                        {{ analytics.campaigns.active || 0 }}
                      </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Inactive: </span>
                      <span>
                        {{ analytics.campaigns.inactive || 0 }}
                      </span>
                    </h4>
                  </v-sheet>
                </v-tab-item>
                <v-tab-item>
                  <v-sheet class="pa-3">
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Total:</span>
                      <span>
                        {{ analytics.emails.total || 0 }}
                      </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Sent:</span>
                      <span>
                        {{ analytics.emails.active || 0 }}
                      </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Pending:</span>
                      <span>
                        {{ analytics.emails.inactive || 0 }}
                      </span>
                    </h4>
                  </v-sheet>
                </v-tab-item>
              </v-tabs>
            </v-sheet>
            <!-- <v-sheet
              class="col ma-1 pa-3 d-flex align-content-center flex-column rounded-10"
              outlined
            >
              <h5 class="text-center primary--text fullwidth fs-16 fw-700">
                Email Credits
              </h5>
              <h4
                class="subtitle-2 d-flex mb-1 align-center justify-space-between fullwidth primary--text"
              >
                <span>Balance:</span>
                <span>
                  {{ analytics.credits.balance || 0.0 | money }}
                </span>
              </h4>
              <h4
                class="subtitle-2 d-flex mb-1 align-center justify-space-between fullwidth primary--text"
              >
                <span>Last Recharge Amount:</span>
                <span>
                  {{ analytics.credits.last_recharge_amount || 0.0 | money }}
                </span>
              </h4>
              <h4
                class="subtitle-2 d-flex mb-1 align-center justify-space-between fullwidth primary--text"
              >
                <span>Last Recharge Date:</span>
                <span>
                  {{ analytics.credits.last_recharge_date | format('ll') }}
                </span>
              </h4>
              <v-btn
                color="primary"
                depressed
                class="my-3 text-none"
                @click.stop="
                  $router.push({
                    name: 'wallet-top-up',
                    query: { for: 'email' }
                  })
                "
              >
                Recharge Email Credits
              </v-btn>
            </v-sheet> -->
          </v-row>
        </v-sheet>
      </template>
      <template v-slot:row-slot="{ item }">
        <td :id="`campaign-${item.id}`">{{ item.name | ucwords }}</td>
        <td>{{ item.props.subject | trunc_mid(20, 20) }}</td>
        <td class="text-center">{{ item.iterations.length }}</td>
        <td>
          <div class="d-flex flex-column">
            <span>{{ concatedLeads(item, 2) }} </span>
            <span>{{ concatedGroups(item, 2) }}</span>
          </div>
        </td>
        <td class="text-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="success"
                small
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                label
              >
                {{ item.sent || 0 }}
              </v-chip>
            </template>
            <span>Email sent</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="error"
                small
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                label
              >
                {{ item.pending || 0 }}
              </v-chip>
            </template>
            <span>Email pending</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                small
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                label
              >
                {{ item.total || 0 }}
              </v-chip>
            </template>
            <span>
              Total emails. Further emails will be generated by the system
            </span>
          </v-tooltip>
        </td>
        <td class="text-center">{{ item.status | ucwords }}</td>
        <ActionDropdown
          :item="item"
          :has-edit="can_edit_campaign(item)"
          :has-delete="can_delete_campaign(item)"
          :has-view="false"
          @delete="action_confirm_dialog(true, item)"
          @edit="add_edit_campaign(item)"
        >
          <template v-slot:extra>
            <v-list-item
              v-if="item.total > 0"
              @click="
                $router.push({
                  name: 'campaign-emails',
                  params: { id: item.id }
                })
              "
            >
              <v-list-item-title>
                <v-icon color="primary" left>mdi-email-search-outline</v-icon>
                <span class="subtitle-2 primary--text">Email Logs</span>
              </v-list-item-title>
            </v-list-item>
          </template>
        </ActionDropdown>
      </template>
    </VueTable>
    <DeleteDialog
      :open-dialog.sync="confirm_dialog"
      title="Delete Campaign"
      text-content="Are you sure you want to delete this campaign?<br> This cannot be undone!"
      @delete="handleDeleteCampaign"
      delete-button-text="Yes! Delete Campaign"
    ></DeleteDialog>
  </div>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import http_build_query from 'js-http-build-query'
import VueTable from '@/common/VueTable/VueTable.vue'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'
import { lead_mixins } from '@/modules/Leads/lead_mixins'
import { email_campaign_mixins } from '@/modules/Campaign/email_campaign_mixins'
import { mapGetters } from 'vuex'
import DeleteDialog from '@/common/DeleteDialog.vue'
import _reverse from 'lodash/reverse'
import _map from 'lodash/map'

export default {
  name: 'EmailCampaign',
  components: { DeleteDialog, ActionDropdown, VueTable },
  mixins: [fetch_services, lead_mixins, email_campaign_mixins],
  data: () => ({
    open_mail_creator: false,
    componentKey: 0,
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true,
        align: 'left',
        width: 250
      },
      { text: 'Subject', value: 'subject', sortable: true, align: 'left' },
      { text: 'Iterations', sortable: false, align: 'center' },
      { text: 'Recipients', sortable: false, align: 'left' },
      { text: 'Emails Status', sortable: false, align: 'center', width: 200 },
      {
        text: 'Campaign Status',
        value: 'status',
        sortable: true,
        align: 'center',
        width: 160
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'center',
        width: '40px'
      }
    ],
    filters: {
      status: 'all'
    },
    analytics: {
      graph: [],
      campaigns: [],
      emails: [],
      credits: []
    }
  }),
  watch: {
    filters: {
      handler: function (val) {
        this.loadCampaigns()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    //this.loadCampaigns();
    this.getAnalytics()
  },
  computed: {
    ...mapGetters(['user']),
    analytics_values() {
      return _reverse(_map(this.analytics.graph, 'count'))
    },
    analytics_labels() {
      return _reverse(_map(this.analytics.graph, 'label'))
    },
    options() {
      return {
        chart: {
          id: 'vuechart'
        },
        xaxis: {
          categories: this.analytics_labels
        },
        stroke: {
          curve: 'smooth'
        }
      }
    },
    series() {
      return [
        {
          name: 'Campaign',
          data: this.analytics_values
        }
      ]
    }
  },
  methods: {
    add_edit_campaign(item, targetType = 'groups') {
      if (item)
        this.$router.push({
          name: 'campaign-emails-generator',
          params: { id: item.id },
          query: {
            redirect_uri: this.$route.fullPath,
            targetType: item.target_type
          }
        })
      else
        this.$router.push({
          name: 'campaign-emails-generator',
          query: { redirect_uri: this.$route.fullPath, targetType: targetType }
        })
    },
    getAnalytics() {
      request.get(`api/lead-campaign/email/analytics`).then(({ data }) => {
        this.analytics = data
      })
    },
    can_edit_campaign(item) {
      return (
        this.user.id === item.user_id ||
        this.user.is_manager ||
        this.user.is_admin
      )
    },
    can_delete_campaign(item) {
      return (
        this.user.id === item.user_id ||
        this.user.is_manager ||
        this.user.is_admin
      )
    },
    editCampaign(item) {
      this.activeItem = item
      this.open_mail_creator = true
    },
    loadCampaigns() {
      this.loadItems(
        `api/lead-campaign/email?${http_build_query(this.filters)}`,
        true
      )
    },
    loadMoreCampaign() {
      this.loadMoreItems()
    },
    handleDeleteCampaign() {
      this.deleteItem(
        `api/lead-campaign/email/${this.activeItem.id}`,
        'Campaign deleted',
        () => {
          this.action_confirm_dialog(false, null)
          this.getAnalytics()
        },
        null
      )
    },
    createSignature() {
      this.$router.push({
        name: 'builders-email--signature',
        query: { redirect: this.$route.fullPath }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>

<style lang="scss">
.email-campaign {
  #panel {
    .v-expansion-panel-content__wrap {
      padding: 0 8px 8px !important;
    }
  }
}
</style>
