<template>
  <div class="call-campaign">
    <v-card flat class="border-1 border-color--grey">
      <v-card-title>
        Call Campaign
      </v-card-title>
      <v-card-text>
        <empty headline="Coming soon" icon="mdi-hammer-wrench"></empty>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CallCampaign"
}
</script>

<style scoped lang="scss">
 
 
</style>