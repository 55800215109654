<template>
  <v-card class="project-campaign rounded-10" outlined flat>
    <v-tabs
      class="tabs mb-2 border-bottom-1 overflow-hidden border-color--grey"
      centered
      grow
      height="48px"
      hide-slider
      v-model="active_tab"
      :show-arrows="mdAndDown"
    >
      <template v-for="(tab, index) in tabs">
        <v-tab
          class="text-none tab-it"
          active-class="active-tab"
          v-if="tab.active"
          :key="index"
          exact
          @click="handleChangeTab(tab, index)"
        >
          <v-icon small left>{{ tab.icon }}</v-icon>
          {{ tab.name | ucwords }}
        </v-tab>
      </template>
    </v-tabs>
    <EmailCampaign v-if="active_tab === 0"></EmailCampaign>
    <SMSCampaign :outlined="false" v-if="active_tab === 1"></SMSCampaign>
    <CallCampaign v-if="active_tab === 2"></CallCampaign>
  </v-card>
</template>

<script>
import EmailCampaign from '@/modules/Campaign/pages/EmailCampaign.vue'
import SMSCampaign from '@/modules/Campaign/pages/SMSCampaign.vue'
import CallCampaign from '@/modules/Campaign/pages/CallCampaign.vue'

export default {
  name: 'Campaign',
  components: { CallCampaign, SMSCampaign, EmailCampaign },
  data: () => ({
    active_tab: 'email',
    text_tab: 'email',
    tabs: [
      {
        name: 'Email Campaign',
        id: 0,
        type: `email`,
        icon: 'mdi-email-variant',
        active: true
      },
      {
        name: 'SMS/MMS Campaign',
        id: 1,
        type: `sms`,
        icon: 'mdi-cellphone-iphone',
        active: true
      },
      {
        name: 'Call Campaign',
        id: 2,
        type: `call`,
        icon: 'mdi-account-tie-voice-outline',
        active: false
      }
    ]
  }),
  computed: {
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        { text: 'Campaigns', disabled: true, router_name: null },
        { text: this.text_tab, disabled: true, router_name: null }
      ]
    }
  },
  watch: {
    '$route.params': {
      handler(val) {
        this.active_tab = 'email'
        this.text_tab = val.type
        if (
          val.hasOwnProperty('type') &&
          ['email', 'sms', 'call'].includes(val.type)
        ) {
          this.active_tab = this.tabs.findIndex((i) => i.type === val.type)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  methods: {
    handleChangeTab(tab, index) {
      this.active_tab = index
      this.$router.push({
        name: 'campaigns',
        params: {
          type: tab.type
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.v-tab {
  color: #223a6b !important;
  background: #ffffff !important;
  .v-icon {
    color: #223a6b !important;
  }
  &.active-tab {
    background: #223a6b !important;
    color: #ffffff !important;
    .v-icon {
      color: #ffffff !important;
    }
  }
}
</style>
