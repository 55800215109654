<template>
  <div class="sms-campaign">
    <VueTable
      :items="items"
      :headers="headers"
      :show-row-actions="true"
      @load-more="loadMoreItems"
      :show-select="false"
      :outlined="false"
      flat
      min-height="80vh"
      :has-header="false"
      icon="mdi-alpha-c-box-outline"
      :key="componentKey"
      :no-more-data="!has_more_items"
      empty-text="No campaigns yet"
      :loading="loading_items"
    >
      <template v-slot:header-left-toolbar>
        <h1 class="my-2 primary--text fw-700 ls-1 fs-16">
          SMS/MMS Campaign Analytics
        </h1>
      </template>
      <template v-slot:header-toolbar>
        <v-row dense align="center" justify="end">
          <v-btn
            @click="$router.push({ name: 'smsmms-settings' })"
            color="primary"
            class="text-none ma-1"
          >
            <v-icon left>mdi-cog</v-icon> Settings
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="text-none light--text"
            @click="add_edit_campaign(null)"
          >
            <v-icon small left>mdi-plus-circle-outline</v-icon>
            New Campaign
          </v-btn>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                color="primary"
                class="ml-1 text-none"
                v-bind="attrs"
                v-on="on"
              >
                Status: {{ filters.status | ucwords }}
                <v-spacer />
                <v-icon right> mdi-menu-swap </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="item in ['all', 'active', 'inactive']"
                :key="item"
                @click="filters.status = item"
              >
                <v-list-item-title class="primary--text fw-700">
                  {{ item | ucwords }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
      <template v-slot:header>
        <v-sheet class="mb-2">
          <v-row dense align="stretch" justify="center">
            <v-col
              :cols="mdAndUp ? 7 : 12"
              class="border-1 ma-1 border-color--grey rounded-10"
            >
              <apexchart
                height="160"
                type="line"
                :options="options"
                :series="series"
              ></apexchart>
            </v-col>
            <v-sheet class="col ma-1 rounded-10" outlined>
              <v-tabs fixed-tabs>
                <v-tab class="text-none fw-700 fs-16">Campaigns</v-tab>
                <v-tab class="text-none fw-700 fs-16">SMS/MMS</v-tab>
                <v-tab-item class="fullwidth fill-height">
                  <v-sheet class="pa-3">
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Total:</span>
                      <span class="float-right">
                        {{ analytics.campaigns.total || 0 }}
                      </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Active:</span>
                      <span class="float-right">
                        {{ analytics.campaigns.active || 0 }}
                      </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span> Inactive:</span>
                      <span class="float-right">
                        {{ analytics.campaigns.inactive || 0 }}
                      </span>
                    </h4>
                  </v-sheet>
                </v-tab-item>
                <v-tab-item class="fullwidth fill-height">
                  <v-sheet class="pa-3">
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Total SMS/MMS:</span>
                      <span class="float-right">
                        {{ analytics.smsmms.total || 0 }}
                      </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Sent:</span>
                      <span class="float-right">
                        {{ analytics.smsmms.active || 0 }}
                      </span>
                    </h4>
                    <h4
                      class="subtitle-2 mb-1 primary--text d-flex align-center justify-space-between"
                    >
                      <span>Pending:</span>
                      <span class="float-right">
                        {{ analytics.smsmms.inactive || 0 }}
                      </span>
                    </h4>
                  </v-sheet>
                </v-tab-item>
              </v-tabs>
            </v-sheet>
          </v-row>
        </v-sheet>
      </template>
      <template v-slot:row-slot="{ item }">
        <td :id="`campaign-${item.id}`">{{ item.name | ucwords }}</td>
        <td>
          <div class="d-flex flex-column">
            <span>{{ concatedLeads(item, 2) }} </span>
            <span>{{ concatedGroups(item, 2) }}</span>
          </div>
        </td>
        <td class="text-center">{{ item.iterations.length || 0 }}</td>
        <td class="text-left">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="success"
                small
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                label
              >
                {{ item.sent || 0 }}
              </v-chip>
            </template>
            <span>SMS/MMS sent</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="error"
                small
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                label
              >
                {{ item.pending || 0 }}
              </v-chip>
            </template>
            <span>SMS/MMS pending</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                small
                v-bind="attrs"
                v-on="on"
                class="mx-1"
                label
              >
                {{ item.total || 0 }}
              </v-chip>
            </template>
            <span>
              Total SMS/MMS. Further SMS/MMS will be generated by the system
            </span>
          </v-tooltip>
        </td>
        <td class="text-center">{{ item.status | ucwords }}</td>
        <td
          class="text-center"
          :title="item.twilio_number.source_number"
          v-if="item.twilio_number"
        >
          {{ item.twilio_number.friendly_name | ucwords }}
        </td>
        <td class="text-center error--text" v-else>Not set</td>
        <ActionDropdown
          :item="item"
          :has-edit="can_edit_campaign(item)"
          :has-delete="can_delete_campaign(item)"
          :has-view="false"
          @delete="action_confirm_dialog(true, item)"
          @edit="add_edit_campaign(item)"
        >
          <template v-slot:extra>
            <v-list-item
              v-if="item.total > 0"
              @click="
                $router.push({
                  name: 'campaign-smsmms',
                  params: { id: item.id }
                })
              "
            >
              <v-list-item-title class="subtitle-2 primary--text">
                <v-icon color="primary" left>mdi-email-search-outline</v-icon>
                SMS/MMS Logs
              </v-list-item-title>
            </v-list-item>
          </template>
        </ActionDropdown>
      </template>
    </VueTable>
    <SMSCampaignCreator
      :item="activeItem"
      :dialog.sync="open_sms_creator"
      @save="handleSaveSMSCampaign"
      @update="handleUpdateSMSCampaign"
    ></SMSCampaignCreator>
    <DeleteDialog
      :open-dialog.sync="confirm_dialog"
      title="Delete Campaign"
      text-content="Are you sure you want to delete this campaign?<br> This cannot be undone!"
      @delete="handleDeleteCampaign"
      delete-button-text="Yes! Delete Campaign"
    ></DeleteDialog>
  </div>
</template>

<script>
import VueTable from '@/common/VueTable/VueTable.vue'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'
import SMSCampaignCreator from '@/modules/Campaign/components/SMSCampaignCreator.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import http_build_query from 'js-http-build-query'
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import _reverse from 'lodash/reverse'
import _map from 'lodash/map'
import { lead_mixins } from '@/modules/Leads/lead_mixins'

export default {
  name: 'SMSCampaign',
  components: { DeleteDialog, SMSCampaignCreator, ActionDropdown, VueTable },
  mixins: [fetch_services, lead_mixins],
  data: () => ({
    hasNumber: false,
    componentKey: 0,
    open_sms_creator: false,
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true,
        align: 'left',
        width: 250
      },
      { text: 'Recipients', sortable: false, align: 'left' },
      { text: 'Iterations', sortable: false, align: 'center' },
      { text: 'Messaging Status', sortable: false, align: 'left' },
      {
        text: 'Campaign Status',
        value: 'status',
        sortable: true,
        align: 'center',
        width: 150
      },
      {
        text: 'Number Used',
        value: 'twilio_number_id',
        sortable: false,
        align: 'center',
        width: 150
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'center',
        width: '40px'
      }
    ],
    filters: {
      status: 'all'
    },
    analytics: {
      graph: [],
      campaigns: [],
      smsmms: []
    }
  }),
  computed: {
    ...mapGetters(['user']),
    analytics_values() {
      return _reverse(_map(this.analytics.graph, 'count'))
    },
    analytics_labels() {
      return _reverse(_map(this.analytics.graph, 'label'))
    },
    options() {
      return {
        chart: {
          id: 'vuechart'
        },
        xaxis: {
          categories: this.analytics_labels
        },
        stroke: {
          curve: 'smooth'
        }
      }
    },
    series() {
      return [
        {
          name: 'Campaign',
          data: this.analytics_values
        }
      ]
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        this.loadSmsMms()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.checkIfHasNumber()
    this.getAnalytics()
  },
  methods: {
    checkIfHasNumber() {
      request.get(`api/twilio/our-numbers/check`).then(({ data }) => {
        this.hasNumber = data.has_numbers
        if (!data.has_numbers) {
          this.appConfirmation(
            'You didnt have pre-configure number yet for SMS and MMS campaigns. Proceed to configuration?',
            () => {
              this.$router.push({ name: 'smsmms-settings' })
            },
            null,
            'Proceed'
          )
        }
      })
    },
    getAnalytics() {
      request.get(`api/lead-campaign/sms/analytics`).then(({ data }) => {
        this.analytics = data
      })
    },
    loadSmsMms() {
      this.loadItems(
        `api/lead-campaign/sms?${http_build_query(this.filters)}`,
        true
      )
    },
    add_edit_campaign(item, targetType = 'groups') {
      if (item)
        this.$router.push({
          name: 'campaign-smsmms-generator',
          params: { id: item.id },
          query: {
            redirect_uri: this.$route.fullPath,
            targetType: item.target_type
          }
        })
      else
        this.$router.push({
          name: 'campaign-smsmms-generator',
          query: { redirect_uri: this.$route.fullPath, targetType: targetType }
        })
    },
    can_edit_campaign(item) {
      return (
        this.user.id === item.user_id ||
        this.user.is_manager ||
        this.user.is_admin
      )
    },
    can_delete_campaign(item) {
      return (
        this.user.id === item.user_id ||
        this.user.is_manager ||
        this.user.is_admin
      )
    },
    editCampaign(item) {
      this.activeItem = item
      this.open_sms_creator = true
    },
    handleSaveSMSCampaign(payload) {
      this.addItem(
        `api/lead-campaign/sms`,
        payload,
        'New SMS/MMS campaign added!',
        () => {
          this.open_sms_creator = false
          this.getAnalytics()
        },
        null
      )
    },
    handleUpdateSMSCampaign(payload) {
      this.updateItem(
        `api/lead-campaign/sms/${this.activeItem.id}`,
        payload,
        'Campaign updated',
        () => {
          this.open_sms_creator = false
          this.getAnalytics()
        },
        null
      )
    },
    handleDeleteCampaign() {
      this.deleteItem(
        `api/lead-campaign/sms/${this.activeItem.id}`,
        'Campaign deleted',
        () => {
          this.action_confirm_dialog(false, null)
          this.getAnalytics()
        },
        null
      )
    }
  }
}
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.sms-campaign {
  #panel {
    .v-expansion-panel-content__wrap {
      padding: 0 8px 8px !important;
    }
  }
}
</style>
