import request from '@/services/axios_instance'
export const email_campaign_mixins = {
    data: () => ({
        templates: [],
        signatures: []
    }),
    mounted() {
        this.fetchEmailTemplates()
        this.fetchEmailSignatures()
    },
    methods: {
        fetchEmailTemplates() {
            request.get(`api/emails/accessories/template`).then(({ data }) => {
                this.templates = data
            })
        },
        fetchEmailSignatures() {
            request.get(`api/emails/accessories/signature`).then(({ data }) => {
                this.signatures = data
            })
        }
    }
}